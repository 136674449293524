import React from "react";
import styled, { keyframes } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { Title, Section, Box, Text } from "../components/Core";
import { breakpoints } from "../utils";
import imgC20 from "../assets/image/jpeg/nose-hero.jpg";
import imgC21 from "../assets/image/jpeg/micro-hero.jpg";
import imgC22 from "../assets/image/jpeg/exosomes-hero.jpg";
import imgC23 from "../assets/image/jpeg/dermal-hero-pop.jpg";
import imgC24 from "../assets/image/jpeg/profhilo-hero.jpg";
import imgC25 from "../assets/image/jpeg/fat-dissolving-trend.jpg";
import imgC26 from "../assets/image/jpeg/removal-hero.jpg";
import '@fortawesome/fontawesome-free/css/all.min.css';
import Link from 'gatsby-link';

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;

const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const SwipeTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
  padding-bottom:0px;
  color: #1a1a1a;
  font-weight: 300;
  margin-top: 1em;

  @media screen and (max-width: 767px) {
    font-size: 0.8em;
  }
`;

const arrowAnimation = keyframes`
  0% { transform: translateX(0); }
  50% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

const Arrow = styled.i`
  margin-left: 10px;
  font-size: 1em;
  animation: ${arrowAnimation} 1.5s infinite;
  color: #00aec7;

  @media screen and (max-width: 767px) {
    font-size: 0.8em;
  }
`;

const SliderStyled = styled(Slider)`
  .slick-dots {
    position: relative;
    margin-top: 10px;
    li {
      font-size: 0;
      width: 17px;
      height: 8px;
      border-radius: 4px;
      background-color: rgb(171, 142, 102, 0.3);
      margin-left: 5px;
      margin-right: 5px;
      transition: 0.5s;
      &.slick-active {
        width: 45px;
        height: 8px;
        border-radius: 4px;
        background-color: rgb(171, 142, 102);
      }
      button {
        width: 100%;
        height: 100%;
        &:before {
          content: none;
        }
      }
    }
  }

  .slick-slide {
    transition: transform 0.3s ease, filter 0.3s ease;
    padding: 0 10px; /* Add padding between slides */
    &:not(.slick-center) {
      transform: scale(1);
      @media screen and (max-width: 767px) {
        filter: grayscale(100%);
        transform: scale(0.8)
      }
    }
  }

  .slick-center {
    transform: scale(1.2);
  }
`;

const ContentCard = ({
  className,
  image,
  name,
  company,
  children,
  ...rest
}) => (
  <Box
    mx="3px"
    className={`${className}`}
    {...rest}
    css={`
      &:focus {
        outline: none;
      }
      margin: 0 5px; /* Add margin to create gaps between items */
    `}
  >
    <Text color="dark" mb={4}>
      {children}
    </Text>
    <Box className={`d-block justify-content-between`}>
      <Box className="d-block justify-content-center align-items-start" mr={0}>
        <img src={image} alt="Non Surgical Treatments" loading="lazy" className="img-fluid" />
      </Box>
      <div className="text-center">
        <Title fontSize={1} variant="card" mb={3}>
          {name}
        </Title>
        <Text fontSize={2}>{company}</Text>
      </div>
    </Box>
  </Box>
);

const TrendsPage = () => {
  const slickSettings = {
    dots: false,
    infinite: true,
    arrows: false,
    autoplay: true,
    
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: breakpoints.md,
        settings: {
          slidesToShow: 1,
          infinite:true,
          centerMode: true,
          autoplay: false,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "20%", /* Adjusted to account for margins */
        },
      },
    ],
  };

  return (
    <>
      <Section py={4} className="pl-0 pr-0 ml-0 mr-0"> 
     <Container className="pt-3 pb-3">
          <Row className="align-items-center">
            <Col md="9" lg="12" className="" data-aos="fade-up"
              data-aos-duration="400"
              data-aos-once="false"
              data-aos-delay="100">
      
                   <Iwrap>


<Itext as="h2">Non Surgical <span>Treatments</span></Itext>
</Iwrap>
      <SecondText>
    Trending Treatments
      </SecondText>


            
     
            </Col>
            </ Row>
            </Container> <br />
        <Container fluid className="pl-0 pr-0 ml-0 mr-0">
          <Row>
            <Col
              lg="12"
              data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100"
            >
              <SliderStyled {...slickSettings}>
                <Box css={`&:focus {outline: none;}`}>
                <Link to="/non-surgical-nose-job"> <ContentCard image={imgC20} name="Non Surgical Nose Job"> </ContentCard></Link>
                </Box>
                <Box css={`&:focus {outline: none;}`}>
                <Link to="/skinpen-microneedling"> <ContentCard image={imgC21} name="Microneedling"></ContentCard></Link>
                </Box>
                <Box css={`&:focus {outline: none;}`}>
                <Link to="/exosomes-hair-loss"> <ContentCard image={imgC22} name="Exosomes Hair Loss"></ContentCard></Link>
                </Box>
                <Box css={`&:focus {outline: none;}`}>
                <Link to="/dermal-fillers">  <ContentCard image={imgC23} name="Dermal Fillers"></ContentCard></Link>
                </Box>
                <Box css={`&:focus {outline: none;}`}>
                <Link to="/profhilo"> <ContentCard image={imgC24} name="Profhilo"></ContentCard></Link>
                </Box>
                <Box css={`&:focus {outline: none;}`}>
                <Link to="/fat-dissolving">  <ContentCard image={imgC25} name="Fat Dissolving"></ContentCard></Link>
                </Box>
                  <Box css={`&:focus {outline: none;}`}>
                  <Link to="/anti-wrinkle-treatments"> <ContentCard image={imgC26} name="Anti-Wrinkle Treatments"></ContentCard
                  ></Link>
                  </Box>
                 
            

             
              </SliderStyled>
            </Col>
          </Row>
  <SwipeTextContainer>
  Swipe
  <Arrow className="fas fa-arrow-right" />
</SwipeTextContainer>
        </Container>
      </Section>
    </>
  );
};

export default TrendsPage;
