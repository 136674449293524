import React from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text, Span } from "../../../components/Core";
import { device } from "../../../utils";
import MainImage from '../../../assets/image/jpeg/active-acne.jpg';


const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;

const BAcnffeCauddsesPage = () => (
  <>
    {/* <!-- Content section 1 --> */}
  
 
    <Section bg="#ffffff"
py={4}
      className=""
     
    >
      <Container className="pt-3 pb-3">
        <Row className="pb-4 align-items-center">
      
          <Col lg="12" className="order-lg-1 mt-lg-0  pb-lg-5">
            <div
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
         
                <SecondText>
                Symptoms & Causes of Crow's Feet
                </SecondText>

               
           
              </div>
            </div>
          
          </Col>

         
        </Row>

        <Row className="align-items-center">
            <Col lg="12">
            <Text>
            Sun exposure, smoking, genetics, and frequent facial expressions can all contribute to the formation of crow's feet. Sun exposure can damage the skin's collagen and elastin fibres, making it more prone to wrinkling. Smoking can also lead to premature ageing of the skin.


<br /> <br />
               </Text>

               <Text>
              Crow's feet are a cosmetic concern defined by the appearance of fine lines and wrinkles around the corners of the eyes rather than a medical condition with particular symptoms. When we smile, squint, or make other facial gestures that involve the muscles surrounding our eyes, these lines become more visible. <br /> <br />

 

Crow's feet can be mild or severe, appearing as thin, shallow lines or deep creases extending from the corners of the eyes to the temples. 
</Text>
            </Col>

        </Row>


       
   
      </Container>

    
     
    </Section>
  
    
  </>
);

export default BAcnffeCauddsesPage;
