import React from "react";
import { rgba } from "polished";
import { Col } from "react-bootstrap";
import { Box, Text } from "./Core";
import styled, { keyframes } from "styled-components";
import { Link } from "gatsby";

const animRippleOut = keyframes`
  100% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
`;

const InnerCircle = styled(Box)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
`;

const OuterCircle = styled(Box)`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: absolute;
  transform: perspective(1px) translateZ(0);
  background-color: ${({ theme, bg }) => rgba(theme.colors[bg], 0.06)};
  &:before {
    content: "";
    position: absolute;
    border: ${({ theme, bg }) => `6px solid ${rgba(theme.colors[bg], 0.08)}`};
    border-radius: 500px;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    animation: ${animRippleOut} 1.2s linear 1s infinite;
  }
`;

const CircleImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const FeatureCardContainer = styled(Box)`
  background-color: ${({ theme }) => theme.colors.light};
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 30px rgba(49, 91, 199, 0.1);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Add space between image and text */
`;

const FeatureCardContent = styled.div`
  flex-grow: 1; /* Allow the title to take available space */
`;

const FeatureCard = ({ color = "primary", to, title, imageSrc, ...rest }) => (
  <Col xs="12" sm="6" md="4" lg="4" className="mb-4">
    <Link to={to} style={{ textDecoration: "none", color: "inherit" }}>
      <FeatureCardContainer {...rest}>
        <Box
          size={69}
          minWidth={69}
          minHeight={69}
          borderRadius="50%"
          color={color}
          fontSize="28px"
          className="d-flex justify-content-center align-items-center"
          mr="20px"
        >
          {imageSrc ? (
            <CircleImage src={imageSrc} alt={title} />
          ) : (
            <>
              <InnerCircle bg={color} />
              <OuterCircle bg={color} />
            </>
          )}
        </Box>
        <FeatureCardContent>
          <Text
            color="heading"
            as="h3"
            fontSize={2}
            fontWeight={500}
            letterSpacing={-0.75}
            mb={0}
          >
            {title}
          </Text>
        </FeatureCardContent>
      </FeatureCardContainer>
    </Link>
  </Col>
);

export default FeatureCard;
